h2 {
    font-family: 'AvenirNext';
    color: black;
    font-weight: normal;
    margin: 0;
    font-size: 24px;
}

body {
  background-color: #f7f8fa;
  font-family: 'AvenirNext';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b, strong {
  font-weight: 600;
}

.ant-drawer {
  z-index: 1051;
}

.ant-drawer-default {
  z-index: 1000;
}

.ant-select-dropdown {
  z-index: 1052;
}

.ant-btn-primary {
    box-shadow: none !important;
}

/* correct z-index for normal work react-sortable-hoc active sortable element with ant drawer */
.sortableHelper {
    z-index: 1000;
}

.sortableHelper .ant-collapse-header {
    font-family: 'AvenirNext';
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    -webkit-letter-spacing: 0.25px;
    -moz-letter-spacing: 0.25px;
    -ms-letter-spacing: 0.25px;
    letter-spacing: 0.25px;
    color: #4f5964;
    background-color: #fff !important;
    padding: 20px 20px 20px 45px!important;
    position: relative;
    padding: 12px 16px;
    padding-left: 40px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-bottom: 1px solid transparent;
}

.sortableHelper .ant-collapse-header .ant-collapse-arrow {
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    left: 16px;
    display: inline-block;
    font-size: 12px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.sortableHelper .ant-collapse-item-active .ant-collapse-header {
    border-bottom: 1px solid transparent;
}

.sortableHelper .ant-collapse-content {
    border: none;
    background-color: rgba(255,255,255, 0.5) !important;
}

.sortableHelper .ant-collapse-header .ant-collapse-arrow {
    margin-left: 5px;
}

.sortableHelper .ant-collapse-content-box {
    padding-left: 20px;
    padding-top: 0;
    padding-bottom: 0;
    background-color: rgba(255,255,255, 0.5) !important;
}

.sortableHelper svg {
    opacity: 1 !important;
}

.ant-tooltip-inner {
    text-align: center;
}

.sortableHelper {
    opacity: 0.9;
}

.sortableHelper .ant-collapse-header {
    background-color: rgba(255,255,255, 0.5) !important;
}

.list_popover .ant-popover-inner-content {
    padding: 0;
}

.info_video_modal .ant-modal-content {
  border-radius: 10px;
}

.ant-popover-arrow, .ant-tooltip-arrow {
  display: none;
}

.ant-tooltip-inner {
  padding: 10px;
}

.empty-borders .ant-popover-inner-content {
  padding: 0;
}

.empty-borders .ant-modal-header {
  border: none;
}

.verify-phone-modal .ant-modal-header {
  padding: 0px 24px;
}

.clear-vertical-padding .ant-popover-inner-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.ant-drawer-header .ant-drawer-title {
  width: 100%;
}

.ant-table-header::-webkit-scrollbar-line {
  background: transparent;
}

.runWin32 ::-webkit-scrollbar {
  height: 7px;
  width: 7px;
  background: transparent;
  border: none;
}

.runWin32 :hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2) !important;
  border-radius: 1ex;
  opacity: 0.1;
  
}

.runWin32 ::-webkit-scrollbar-corner {
  background: transparent;
  opacity: 0;
}

.notification .ant-notification {
  width: 450px;
  top: 69px !important;
  z-index: 9999;
}

.notification.ant-notification-notice {
  padding: 20px;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0,0,0,0.2) !important;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.2) !important;
}

.notification .ant-notification-notice-icon {
  margin-left: 0;
}

.notification .ant-notification-notice-message {
  margin-left: 75px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
  color: #071427;
}

.notification .ant-notification-notice-description {
  margin-left: 75px;
  font-size: 12px;
  font-weight: 500;
  color: #979FA8;
}

.notification .ant-notification-notice-close {
  top: 10px;
}

.mailbox-notification {
  width: 280px;
  height: 195px;
  padding: 20px;
}

.mailbox-notification .ant-notification-notice-message, .ant-notification-notice-with-icon .ant-notification-notice-description  {
  margin-left: 0;
}

.mailbox-notification .ant-notification-notice-message {
  font-weight: 400;
  font-size: 25px;
  line-height: 35px;
  color: #4F5964;
}

.draggable-line {
  border-radius: 2px !important;
  left: 110px !important;
  border-top: 2px solid #1890FF;
  width: 352px !important;
  border-left: 0 !important;
  border-bottom: 0 !important;
  margin-top: 10px !important;
}

.draggable-line-normal {
  border-bottom: 2px solid #1890FF !important;
}

.ant-drawer-wrapper-body {
  overflow-x: hidden;
}

@media (max-width: 890px) {
  .intercom-lightweight-app-launcher {
    bottom: 15px !important;
    right: 15px !important;
  }

  .ant-drawer-content-wrapper { 
      width: 100vw !important;
  }
}


@media (min-width: 1500px) {
  .draggable-line {
    left: 130px !important;
    margin-top: 0px !important;
  }
}

.ant-white-modal .ant-modal-header {
  border-bottom: 1px solid transparent;
}

.ant-white-modal .ant-modal-body {
  padding-top: 0;
}

@media (max-width: 1000px) {
  .ant-tablet-width .ant-modal {
    width: 100% !important;
  }
}

.overlay-popover {
  width: 170px;
  padding-bottom: 0;
  padding-top: 0;
  height: 260px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.07), 0px 1px 18px rgba(0, 0, 0, 0.06), 0px 3px 5px rgba(0, 0, 0, 0.1);
}

.overlay-popover .ant-popover-inner {
  box-shadow: none;
}

.overlay-popover .ant-popover-inner-content {
  padding: 0;
}

.add-to-sequence .ant-select-dropdown-menu-item-disabled {
  padding: 2px 0 !important;
}

.versions-inner .ant-popover-inner-content {
  padding: 6px 16px;
}

.email-inner .ant-popover-inner-content {
  padding: 6px 0px;
}

.ant-tooltip-inner {
  background-color: #071427;
  padding: 6px;
  font-weight: 500;
  font-size: 12px;
  min-height: 27px;
}

.ant-tooltip {
  z-index: 1400;
}

.nps-score-modal {
  .ant-modal-content {
    border-radius: 10px !important;
  }
}

.ant-select-dropdown-menu {
  padding: 0;
}

.ant-select-dropdown-menu-item {
  color: #4F5964;
  font-size: 12px;
  font-weight: 500;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #E8F4FF;
  color: #1890FF;
}
